<!-- 
author:张洪志
description: 全部定投
 -->
<template>
  <div class="content">
    <fund-nav />
    <div class="logo_div">
      <div class="title_div">
        <div class="title_content">
          <div class="content_width">
            <div>
              <p class="title_eng">AUTOMATIC INVESTMENT PLAN</p>
              <p class="title_chn">基金定投</p>
              <p class="title_rule">定投是定期定额投资的简称，类似银行零存整取，在固定时间(如每月18日)以固定金(如500元)投资。 了解计算规则>></p>
            </div>
            <div align="right">
              <div style="display: inline-flex">
                <div class="justify-center pensionDiv">
                  <p class="pensionFont">养老规划 →</p>
                </div>
                <div class="justify-center">
                  <p class="pFont">长线产品</p>
                </div>
                <div class="justify-center">
                  <p class="pFont">理财规划</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="banner_fixed">
      <div class="content_width banner_fixed_content">
        <span class="icon ico-calc"></span>
        <router-link class="btn" :to="{name:'fixedCal'}">定投计算器</router-link>
        <span class="line">|</span>
        <span class="icon iconfont icon-a-fundmarket-points"></span>
        <router-link :to="{name:'FundSetFing'}" class="btn">我的定投</router-link>
        <span class="line">|</span>
        <span class="icon iconfont icon-a-fundmarket-points"></span>
        <router-link class="btn" :to="{name:'Fixedareas'}">全部定投</router-link>
      </div>
    </div>
    
    <div class="fund-order vw">
      <div :class="['flex-between fund-cat-sec']">
        <div :class="['fund-cat-all',{'current': !fundOrderCat}]" @click="getFundSort()">
          <p>全部基金</p>
        </div>
        <div class="fund-cat-box flex-between">
          <div :class="{'current': fundOrderCat===item.code}" v-for="item in fundOrderCats" :key="item.code" @click="getFundSort(item.code)">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="flex-between fund-order-title fund-order-list">
        <span>基金代码</span>
        <span class="name">基金名称</span>
        <span>风险等级</span>
        <span class="flex-between">
          <span>最新净值</span>
          <i :class="['btn', fundSortStyle('newestValue')]" @click="sortFund('newestValue')"></i>
        </span>
        <span class="flex-between">
          <span>日涨跌幅</span>
          <i :class="['btn', fundSortStyle('changeDay')]" @click="sortFund('changeDay')"></i>
        </span>
        <span class="flex-between">
          <span>
            近一个月<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeMonth')]" @click="sortFund('changeMonth')"></i>
        </span>
        <span class="flex-between">
          <span>
            近三个月<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeThreeMonth')]" @click="sortFund('changeThreeMonth')"></i>
        </span>
        <span class="flex-between">
          <span>
            近半年<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeHalfYear')]" @click="sortFund('changeHalfYear')"></i>
        </span>
        <span class="flex-between">
          <span>
            近一年<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeYear')]" @click="sortFund('changeYear')"></i>
        </span>
        <span>买入费率</span>
        <span class="action">操作</span>
      </div>
      <div class="flex-between dark fund-order-content fund-order-list" v-for="item in fundOrderData" :key="item.fundCode">
        <span class="">{{ item.fundCode }}</span>
        <router-link class="name" :to="{name:'FundDetail',params:{fundCode: item.fundCode}}">{{ item.fundName }}</router-link>
        <span :class="item.ofundRisklevel > 1 ? 'orange' : item.ofundRisklevel > 3 ? 'red' : ''">{{ item.risklevel }}</span>
        <span class="value dark">
          <span>{{ item.newestValue }}</span>
          <br>
          <span class="gray">{{ item.lastDate }}</span>
        </span>
        <span :class="formatRiseStyle(item.changeDay)">{{ item.changeDay }}%</span>
        <span :class="formatRiseStyle(item.changeMonth)">{{ item.changeMonth }}%</span>
        <span :class="formatRiseStyle(item.changeThreeMonth)">{{ item.changeThreeMonth }}%</span>
        <span :class="formatRiseStyle(item.changeHalfYear)">{{ item.changeHalfYear }}%</span>
        <span :class="formatRiseStyle(item.changeYear)">{{ item.changeYear }}%</span>
        <span :class="formatRiseStyle(item.saleFeeRate)">{{ item.saleFeeRate}}</span>
        <span class="action flex-between">
          <input type="button" value="一键定投" class="btn btn-orange-plain btn-buy" @click="buyFund(item.fundCode)">
        </span>
      </div>
    </div>
    <div class="page-bar vw">
      <page-bar :total="pageTotal" :display="5" @click="goPage" />
    </div>
  </div>
</template>

<script>
import FundNav from '../components/FundNav.vue'
  import PageBar from '@/components/Pagebar.vue'
import {getThemeList, getFixedFundList} from '@/api/fixedarea';
import {getDicts} from '@/api/dict.js'

export default {
  name: "FixedAreas",
  components:{
    FundNav,
    PageBar
  },
  data() {
    return {
      themeIndex: 0,
      themeList: [], // 主题列表
      themeData: {}, // 当前主题信息
      themeFundList: [], // 主题基金列表
      
      fundOrderCats: [],
      fundOrderCat: '',
      fundOrderType: {},
      fundOrderData: [],
      riskLevels: [],
      
      pageTotal: 0,
      pageNum: 1,
    }
  },
  created() {
    // 查询定投主题列表
    this.getThemeList();
    // 查询定投基金列表
    this.getFundType()
    this.getRiskLevel().then(() => {
      this.getFundSort()
    })
  },
  methods: {
    /** 切换主题 */
    changeTheme(themeId,index) {
      this.themeIndex = index
      this.getThemeFundList(themeId)
    },
    /** 获取主题 */
    getThemeList() {
      var param = {
        prodType: "1", // 公募
        channelType: "2", // 渠道 web
        pageNum: 1,
        pageSize: 100
      }
      getThemeList(param).then(res => {
        if (res.code == 200) {
          this.themeList = res.data.data;
          this.themeData = this.themeList[0];
          this.getThemeFundList(this.themeData.themeId);
        }
      })
    },
    /** 获取主题基金 */
    getThemeFundList(themeId) {
      var param = {
        pageNum: 1,
        pageSize: 10,
        allowFix: "1", // 定投
        privateFlag: "0", // 公募
        customerWebInvestTheme: themeId
      };
      getFixedFundList(param).then(result => {
        if(!result.data) {
          return;
        }
        let data = result.data.data.forEach(m => {
          m.riskLevel = m.ofundRisklevel
          m.fundType = m.ofundType
        })
        this.themeFundList = data
      })
    },
    /** 购买基金 */
    buyFund(fundCode) {
      this.$router.push({name:'AddProcess', params:{id:fundCode}})
    },
    /** 获取基金类型 */
    getFundType() {
      getDicts('sys_fund_type').then(result => {
        if(!result.data) {
          return
        }
        this.fundOrderCats = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 获取基金风险级别 */
    getRiskLevel() {
      return getDicts('sys_fund_risklevel').then(result => {
        if(!result.data) {
          return
        }
        this.riskLevel = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
      })
    },
    /** 基金排序箭头样式 */
    fundSortStyle(key) {
      return this.fundOrderType[key] === '-1' ? 'ico-sort-down' : this.fundOrderType[key] === '1' ? 'ico-sort-up' : 'ico-sort'
    },
    /** 基金排序 */
    sortFund(key) {
      let order = this.fundOrderType[key]
      order = !order ? '-1' : order === '-1' ? '1' : ''
      this.fundOrderType = {}
      this.fundOrderType[key] = order
      this.getFundSort(this.fundOrderCat)
    },
    /** 获取基金排行 */
    getFundSort(cat) {
      this.fundOrderCat = cat
      let params = {
        pageSize:6,
        pageNum:this.pageNum,
        allowFix: "1",
        privateFlag: '0'
      }
      params.ofundType = cat
      let _params = {...params,...this.fundOrderType}
      getFixedFundList(_params).then(result => {
        let data = result.data.data
        data.forEach(m => {
          m.risklevel = (this.riskLevel.find(n => m.ofundRisklevel === n.code)||{}).name
          m.ofundRisklevel = parseInt(m.ofundRisklevel)
          m.lastDate = m.lastDate.$formatDate('yyyy-MM-dd')
          m.newestValue = m.newestValue.$trimZero()
          m.changeDay = m.changeDay.$trimZero()
          m.changeMonth = m.changeMonth.$trimZero()
          m.changeThreeMonth = m.changeThreeMonth.$trimZero()
          m.changeHalfYear = m.changeHalfYear.$trimZero()
          m.changeYear = m.changeYear.$trimZero()
          m.saleFeeRate = m.saleFeeRate ? m.saleFeeRate + '%' : '免手续费'
        })
        this.fundOrderData = data
        this.pageTotal = result.data.pages
      })
    },
    /** 格式化基金涨跌样式
     * @param {Number|String} priceRate 涨跌幅
     */
    formatRiseStyle(priceRate) {
      priceRate = priceRate || 0
      priceRate = parseFloat(priceRate)
      return priceRate > 0 ? 'red' : priceRate < 0 ? 'green' : ''
    },
    /** 页跳转 */
    goPage(page) {
      this.pageNum = page
      this.getFundSort(this.fundOrderCat)
    }
  }
}
</script>

<style lang="less" scoped>
  @orange:#CE9B63;
  @gray:#999;
  @red:#D43F3F;
  .orange{color:@orange;}
  .gray{color:@gray;}
  .red{ color:@red;}
  .small{font-size: 12px;}
  .btn-orange-plain{ border:1px solid @orange; color:@orange; background: #fff; border-radius: 3px;}
.content { width: 100%; background: #f3f4f6; }
.content_width { width: 1200px; margin: 0 auto; }
.logo_div { opacity: 1; background: rgba(0, 0, 0, 0.38); height: 331px; background: url("../../../assets/images/fund-market/fixedarealogo.png") no-repeat; background-size: 100% 100%; }
.title_div {display: flex; align-items: center; justify-content: center; }
.title_content { height: 320px; color: white;/*border: 1px solid red;*/
}
.title_eng { margin-top: 56px; font-size: 36px; font-family: B612, B612-Regular; font-weight: 400; text-align: LEFT; line-height: 36px; }
.title_chn { margin-top: 15px; font-size: 66px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold; font-weight: 700; text-align: LEFT; line-height: 66px; letter-spacing: 0px; }
.title_rule { margin-top: 23px; width: 720px; height: 67px; opacity: 1; font-size: 16px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular; font-weight: 400; text-align: LEFT; color: #ffffff; line-height: 32px; }
.justify-center { display: flex; align-items: center; justify-content: center; }
.pensionDiv { width: 129px; height: 36px; border: 1px solid #ffffff; border-radius: 19px; }
.pensionFont { width: 80px; height: 14px; font-size: 14px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular; font-weight: 400; text-align: CENTER; line-height: 14px; }
.pFont { margin-left: 23px; width: 56px; height: 14px; font-size: 14px; font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold; font-weight: 700; text-align: CENTER; line-height: 14px; }
.banner_fixed { height: 68px; background: #ce9b63; font:22px/68px "microsoft yahei"; color:#fff; 
  .icon{margin-right: 12px; font-size: 20px;}
  .line{padding:0 40px; display: inline-block;}
  a{ color:#fff;}
}

.fund-order{ padding: 20px 0; margin-top:40px;
	.fund-cat-box{ width: 1080px; flex-wrap: wrap; justify-content: flex-start;}
	.fund-cat-all,
	.fund-cat-box>div{color: #666; padding: 10px; text-align: center; position: relative; height: 1.5em; cursor: pointer; user-select: none; border-radius: 3px;
  }
	.fund-cat-box>div { margin: 0em 1.5em 0.5em 0; }
	.show-less{ height:3em;}
	.fund-cat-sec {overflow: hidden; font-size: 18px;
		.ico-sort-down{ position: absolute; bottom: -7px; left:45%; display: none;}
		div.current{ border-color: #deb87b; background: #deb87b; color: #fff;}
		.current .ico-sort-down{ display: block;}
	}
	.fund-order-list{ text-align: center;}
	.fund-order-title{ background: #F9F9FA; padding: 0.5em 0; margin-top: 1.5em; align-items: center; font-size:15px;}
	.fund-order-title .btn{ font-size: 1.2em; margin: 0px 0 0 2px;}
	.fund-order-title>span{ justify-content: center; align-items: center;}
	.fund-order-list>span{ width: 7em;}
	.fund-order-list .name{ text-align: left; width:200px;}
	.fund-order-list .action {width:170px;}
	.fund-order-content { padding: 1.8em 0;  line-height: 2em; border-bottom: 1px solid #eee;
		.value{ line-height: 1.5em;}
		.dark{ color: #5d5d5d;}
		.btn-buy{ padding: 10px 50px; height:2.5em;
      &:hover{color:#fff; background: @orange; border-color: @orange;}
    }
	}
}
.page-bar{ text-align: right; background: #fff; padding:1em 0;}
</style>