import request from '@/utils/request'

// 获取主题列表
export function getThemeList(data) {
  return request({
    url: '/api/system/prod/theme/page',
    method: 'post',
    data: data
  })
}

// 获取定投列表
export function getFixedFundList(data) {
  return request({
    url: '/api/sale/fundCommon/page',
    method: 'post',
    data: data
  })
}

